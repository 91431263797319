import React from 'react';
import MainLeftView from "../component/mainleftview";
import {Route, Switch,Link} from "react-router-dom";
import Parameter from '../config/Config';
import {Notify,Notice} from 'zent';
import '../style/css/main.css'
import Service from "../config/Service";
import Main from '../pages/main/index'
import MainSystemRoleIndex from "../pages/main/system/role/index"
import MainSystemRoleEdit from "../pages/main/system/role/edit"
import MainSystemUserIndex from "../pages/main/system/user/index"
import MainSystemUserEdit from "../pages/main/system/user/edit"
import MainSystemProductIndex from "../pages/main/system/product/index"
import MainSystemProductEdit from "../pages/main/system/product/edit"
import MainSystemYewuyuanIndex from "../pages/main/system/yewuyuan/index"
import MainSystemYewuyuanEdit from "../pages/main/system/yewuyuan/edit"
import MainSystemFlashIndex from "../pages/main/system/flash/index"
import MainSystemFlashEdit from "../pages/main/system/flash/edit"
import MainSystemMenuIndex from "../pages/main/system/menu/index"
import MainSystemMenuEdit from "../pages/main/system/menu/edit"
import MainSystemReportIndex from "../pages/main/system/report/index"
import MainConstantCityIndex from "../pages/main/constant/city/index"
import MainConstantCityEdit from "../pages/main/constant/city/edit"
import MainConstantDianpuIndex from "../pages/main/constant/dianpu/index"
import MainConstantDianpuEdit from "../pages/main/constant/dianpu/edit"
import MainConstantSchoolIndex from "../pages/main/constant/school/index"
import MainConstantSchoolEdit from "../pages/main/constant/school/edit"
import MainCustomerUserIndex from "../pages/main/customer/user/index"
import MainCustomerSetUser from "../pages/main/customer/setuser/index"
import MainCustomerUserEdit from "../pages/main/customer/user/edit"
import MainCustomerMerchantIndex from "../pages/main/customer/merchant/index"
import MainCustomerMerchantEdit from "../pages/main/customer/merchant/edit"
import MainConstantWorkClassIndex from "../pages/main/constant/workclass/index"
import MainConstantWorkClassEdit from "../pages/main/constant/workclass/edit"
import MainWorkWorkIndex from "../pages/main/work/work/index"
import MainWorkWorkEdit from "../pages/main/work/work/edit"
import MainConstantWorkContentIndex from "../pages/main/constant/workcontent/index"
import MainConstantWorkContentEdit from "../pages/main/constant/workcontent/edit"
import MainFinancialResultsIndex from "../pages/main/financial/results/index"
import MainFinancialReimbursementIndex from "../pages/main/financial/reimbursement/index"
import MainFinancialReimbursementEdit from "../pages/main/financial/reimbursement/edit"
import MainFinancialVipcodeIndex from "../pages/main/financial/vipcode/index"
import MainCustomerAgentIndex from "../pages/main/customer/agent/index"
import MainCustomerTousuIndex from "../pages/main/customer/tousu/index"
import MainSystemHuomaIndex from "../pages/main/system/huoma/index"
import MainSystemHuomaEdit from "../pages/main/system/huoma/edit"
import MainSystemHuomaListIndex from "../pages/main/system/huoma/list/index"
import MainSystemHuomaListEdit from "../pages/main/system/huoma/list/edit"
import MainDataSimpleIndex from "../pages/main/data/simple/index"
import MainDataSimpleListIndex from "../pages/main/data/simple/index"
import MainAuditMerchantIndex from "../pages/main/audit/merchant/index"
import MainNoticeNoticeIndex from "../pages/main/notice/notice/index"
import MainExitProcessingIndex from "../pages/main/exit/processing/index"
import MainAdAdIndex from "./main/ad/ad";
import MainAdAdEdit from "./main/ad/ad/edit";
let _AlertJson=[];
export default class AllMain extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.state={
            successCode:false,
            menuList:[],
            navId:0,
            menuId:0,
            roleCode:2,
            notice:0
        }
    }
    componentDidMount() {
        this.onAlertBind();
        setInterval(()=>{
            this.onAlertBind();
        },10000)
    }
    onMenuChange=(menuid)=>
    {
        if(menuid>0)
        {
            this.onYzRole(menuid);
        }
        if(this.MainLeft)
        {
            this.MainLeft.onSetMenuId(menuid);
        }
    }
    onRef=(ref)=>{
        this.MainLeft=ref;
    }

    onAlertBind=()=>{
        const _this=this;
        Service.Post({
            name:'AlertMessage',
            action:'alert',
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    const list=result.list;
                    list.map((item,index)=>{
                        _this.onShowAlert(item)
                    })
                }
            }
        })
    }
    onShowAlert=(data)=>{
        const notice=Notice.push(
            <Notice title={data.title} type="info" autoClose={false} position={"right-bottom"}>
                <div className="main-myalert-hd">
                    {data.text}
                </div>
                <div className="main-myalert-bd">
                    <span onClick={this.onToPage.bind(this,data.url,data.id)}>点击查看</span>
                </div>
            </Notice>
        );
        _AlertJson.push({
            id:data.id,
            notice:notice
        })
    }
    onToPage=(url,id)=>{
        _AlertJson.map((item,index)=>{
            if(item.id==id)
            {
                Notice.close(item.notice);
                _AlertJson.splice(index,1)
            }
        })
        this.props.history.push('/main')
        setTimeout(()=>{
            this.props.history.push(url);
        },100)
    }
    onYzRole(rolecode){
        const _this=this;
        Service.Post({
            name:"adminrole",
            action:"role",
            data:{
                rolecode:rolecode
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        roleCode:2
                    })
                }
                else
                {
                    _this.setState({
                        roleCode:1
                    })
                }
            },
            fail(er){
                _this.setState({
                    roleCode:1
                })
            }
        })
    }
    yanzhengBind=()=>{
        const usersession=JSON.parse(sessionStorage.getItem(Parameter.usersession));
        if(!usersession)
        {
            sessionStorage.removeItem(Parameter.usersession);
            window.location.replace("/login")
            return false;
        }
        else
        {
            if(!usersession.id||!usersession.token||!usersession.role)
            {
                sessionStorage.removeItem(Parameter.usersession)
                window.location.replace("/login")
                return false;
            }
        }
        return true;
    }
    render() {
        if(this.yanzhengBind())
        {
            if(this.state.roleCode==2)
            {
                return(<div className="page-main">
                    <MainLeftView onRef={this.onRef}/>
                    <div className="page-right">
                        <Switch>
                            <Route exact render={(props)=>(
                                <Main {...props} parent={this}/>
                            )} path={"/main"}/>
                            <Route exact render={(props)=>(
                                <MainSystemRoleIndex {...props} parent={this}/>
                            )} path={"/main/system/role"}/>
                            <Route exact render={(props)=>(
                                <MainSystemRoleEdit {...props} parent={this}/>
                            )} path={"/main/system/role/edit/:id"}/>
                            <Route exact render={(props)=>(
                                <MainSystemUserIndex {...props} parent={this}/>
                            )} path={"/main/system/user"}/>
                            <Route exact render={(props)=>(
                                <MainSystemUserEdit {...props} parent={this}/>
                            )} path={"/main/system/user/edit/:id"}/>
                            <Route exact render={(props)=>(
                                <MainSystemProductIndex {...props} parent={this}/>
                            )} path={"/main/system/product"}/>
                            <Route exact render={(props)=>(
                                <MainSystemProductEdit {...props} parent={this}/>
                            )} path={"/main/system/product/edit/:id"}/>
                            <Route exact render={(props)=>(
                                <MainSystemYewuyuanIndex {...props} parent={this}/>
                            )} path={"/main/system/yewuyuan"}/>
                            <Route exact render={(props)=>(
                                <MainSystemYewuyuanEdit {...props} parent={this}/>
                            )} path={"/main/system/yewuyuan/edit/:id"}/>
                            <Route exact render={(props)=>(
                                <MainSystemFlashIndex {...props} parent={this}/>
                            )} path={"/main/system/flash"}/>
                            <Route exact render={(props)=>(
                                <MainSystemFlashEdit {...props} parent={this}/>
                            )} path={"/main/system/flash/edit/:id"}/>
                            <Route exact render={(props)=>(
                                <MainSystemMenuIndex {...props} parent={this}/>
                            )} path={"/main/system/menu"}/>
                            <Route exact render={(props)=>(
                                <MainSystemMenuEdit {...props} parent={this}/>
                            )} path={"/main/system/menu/edit/:id"}/>
                            <Route exact render={(props)=>(
                                   <MainSystemReportIndex {...props} parent={this} />
                            )} path={"/main/system/report"}/>
                            <Route exact render={(props)=>(
                                <MainConstantCityIndex {...props} parent={this}/>
                            )} path={"/main/constant/city"}/>
                            <Route exact render={(props)=>(
                                <MainConstantCityEdit {...props} parent={this}/>
                            )} path={"/main/constant/city/edit/:id"}/>
                            <Route exact render={(props)=>(
                                <MainConstantDianpuIndex {...props} parent={this}/>
                            )} path={"/main/constant/dianpu"}/>
                            <Route exact render={(props)=>(
                                <MainConstantDianpuEdit {...props} parent={this}/>
                            )} path={"/main/constant/dianpu/edit/:id"}/>
                            <Route exact render={(props)=>(
                                <MainConstantSchoolIndex {...props} parent={this}/>
                            )} path={"/main/constant/school"}/>
                            <Route exact render={(props)=>(
                                <MainConstantSchoolEdit {...props} parent={this}/>
                            )} path={"/main/constant/school/edit/:id"}/>
                            <Route exact render={(props)=>(
                                <MainCustomerUserIndex {...props} parent={this}/>
                            )} path={"/main/customer/user"}/>
                            <Route exact render={(props)=>(
                                <MainCustomerSetUser {...props} parent={this}/>
                            )} path={"/main/customer/setuser"}/>
                            <Route exact render={(props)=>(
                                <MainCustomerUserEdit {...props} parent={this}/>
                            )} path={"/main/customer/user/edit/:id"}/>
                            <Route exact render={(props)=>(
                                <MainCustomerMerchantIndex {...props} parent={this}/>
                            )} path={"/main/customer/merchant"}/>
                            <Route exact render={(props)=>(
                                <MainCustomerMerchantEdit {...props} parent={this}/>
                            )} path={"/main/customer/merchant/edit/:id"}/>
                            <Route exact render={(props)=>(
                                <MainConstantWorkClassIndex {...props} parent={this}/>
                            )} path={"/main/constant/workclass"}/>
                            <Route exact render={(props)=>(
                                <MainConstantWorkClassEdit {...props} parent={this}/>
                            )} path={"/main/constant/workclass/edit/:id"}/>
                            <Route exact render={(props)=>(
                                <MainWorkWorkIndex {...props} parent={this}/>
                            )} path={"/main/work/work"}/>
                            <Route exact render={(props)=>(
                                <MainWorkWorkEdit {...props} parent={this}/>
                            )} path={"/main/work/work/edit/:id/:copy"}/>
                            <Route exact render={(props)=>(
                                <MainConstantWorkContentIndex {...props} parent={this}/>
                            )} path={"/main/constant/workcontent"}/>
                            <Route exact render={(props)=>(
                                <MainConstantWorkContentEdit {...props} parent={this}/>
                            )} path={"/main/constant/workcontent/edit/:id"}/>
                            <Route exact render={(props)=>(
                                <MainFinancialResultsIndex {...props} parent={this}/>
                            )} path={"/main/financial/results"}/>
                            <Route exact render={(props)=>(
                                <MainFinancialReimbursementIndex {...props} parent={this}/>
                            )} path={"/main/financial/reimbursement"}/>
                            <Route exact render={(props)=>(
                                <MainFinancialReimbursementEdit {...props} parent={this}/>
                            )} path={"/main/financial/reimbursement/edit/:id"}/>
                            <Route exact render={(props)=>(
                                <MainFinancialVipcodeIndex {...props} parent={this}/>
                            )} path={"/main/financial/vipcode"}/>
                            <Route exact render={(props)=>(
                                <MainCustomerAgentIndex {...props} parent={this}/>
                            )} path={"/main/customer/agent"}/>
                            <Route exact render={(props)=>(
                                <MainCustomerTousuIndex {...props} parent={this}/>
                            )} path={"/main/customer/tousu"}/>
                            <Route exact render={(props)=>(
                                <MainSystemHuomaIndex {...props} parent={this}/>
                            )} path={"/main/system/huoma"}/>
                            <Route exact render={(props)=>(
                                <MainSystemHuomaEdit {...props} parent={this}/>
                            )} path={"/main/system/huoma/edit/:id"}/>
                            <Route exact render={(props)=>(
                                <MainSystemHuomaListIndex {...props} parent={this}/>
                            )} path={"/main/system/huoma/list/:id"}/>
                            <Route exact render={(props)=>(
                                <MainSystemHuomaListEdit {...props} parent={this}/>
                            )} path={"/main/system/huoma/list/edit/:id"}/>
                            <Route exact render={(props)=>(
                                <MainDataSimpleIndex {...props} parent={this}/>
                            )} path={"/main/data/simple"}/>
                            <Route exact render={(props)=>(
                                <MainDataSimpleListIndex {...props} parent={this}/>
                            )} path={"/main/data/simplelist"}/>
                            <Route exact render={(props)=>(
                                <MainAuditMerchantIndex {...props} parent={this}/>
                            )} path={"/main/audit/merchant"}/>
                            <Route exact render={(props)=>(
                                <MainNoticeNoticeIndex {...props} parent={this}/>
                            )} path={"/main/notice/notice"}/>
                            <Route exact render={(props)=>(
                                <MainExitProcessingIndex {...props} parent={this}/>
                            )} path={"/main/exit/processing"}/>
                            <Route exact render={(props)=>(
                                <MainAdAdIndex {...props} parent={this}/>
                            )} path={"/main/ad/ad"}/>
                            <Route exact render={(props)=>(
                                <MainAdAdEdit {...props} parent={this}/>
                            )} path={"/main/ad/ad/edit/:id"}/>
                        </Switch>
                    </div>
                </div>)
            }
        }
        return <div>无权限访问</div>;
    }
}
