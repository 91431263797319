import React from 'react';
import Header from '../../../../component/header'
import { Form, FormStrategy, Notify, FormInputField,Button,
    FormNumberInputField,
    FormCheckboxGroupField,
    Checkbox,
    Validators,
    previewImage,
    Icon,
    FormImageUploadField,
    FormColorPickerField,
    FormSelectField,BlockLoading,FormSwitchField} from 'zent';
import Service from "../../../../config/Service";
import Utils from "../../../../config/Utils";
export default class MainAdAdEdit extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            flashId:0,
            editData:{},
            dianpuList:[],
            pagelist:[{key:1,text:'首页'},{key:2,text:'职位列表页'},{key:3,text:'职位详情页'}],
            loading:false
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(190100);
        const id=this.props.match.params.id||0;
        this.setState({
            flashId:id
        })
        this.dianpuBind();
        this.dataBind(id);
    }
    dianpuBind(){
        const _this=this;
        Service.Post({
            name:"admindianpu",
            action:"listbyadmin",
            data:{},
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        dianpuList:result.list
                    })
                }
            }
        })
    }
    dataBind(id){
        if(id<=0)
        {
            return false;
        }
        const _this=this;
        this.setState({
            loading:true
        })
        Service.Post({
            name:"webflash",
            action:"news",
            data:{
                id
            },
            success(result)
            {
                if(result.retcode==200)
                {
                    _this.setState({
                        editData:result.data,
                        loading:false
                    })
                }
                else
                {
                    Notify.error("没有找到相关信息",1500,()=>{
                        _this.setState({
                            loading:false
                        })
                        _this.props.history.goBack();
                    });
                }

            },
            fail(){
                Notify.error("网络连接失败");
                _this.setState({
                    loading:false
                })
            }
        })
    }
    FormView=()=>{
        const _this=this;
        const editData=this.state.editData;
        const stateLoading=this.state.loading;
        const dianpulist=this.state.dianpuList;
        const pagelist=this.state.pagelist;
        const form = Form.useForm(FormStrategy.View);
        const [loading,setLoading]=React.useState(false);
        const [dianpuchecked,setDianpuchecked]=React.useState(false);
        const [indeterminate,setIndeterminate]=React.useState(false);
        const [showimg,setShowimg]=React.useState(editData.img?true:false);
        const onSubmit = React.useCallback(form => {
            setLoading(true);
            const value = form.getSubmitValue();
            return new Promise(resolve => {
                const id=_this.state.flashId;
                let img=editData.img;
                if(value.img&&value.img.length)
                {
                    img=value.img[0].src;
                }
                console.log(value);
                Service.Post({
                    name:"webflash",
                    action:"edit",
                    data:{
                        id,
                        type:2,
                        name:value.name,
                        img:img,
                        dianpu:0,//value.dianpu.key,
                        dianpus:JSON.stringify(value.dianpus),
                        sort:value.sort,
                        state:value.state?1:2,
                        url:value.url,
                        page:value.page.key,
                        frole:2,
                        color:'#ffffff',
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("提交成功",1000,()=>{
                                setLoading(false);
                                _this.props.history.goBack();
                            })
                        }
                        else
                        {
                            Notify.error(result.retmsg);
                            setLoading(false);
                        }
                    },
                    fail(){
                        Notify.error("网络连接失败");
                        setLoading(false);
                    }
                })
            });
        }, []);
        const dianpuonChange=(e)=>{
            setIndeterminate(false)
            const checked=e.target.checked;
            if(checked)
            {
                const items=dianpulist.map((item)=>{
                    return item.key;
                })
                form.patchValue({
                    dianpus:items
                })
                setDianpuchecked(true);
            }
            else
            {
                form.patchValue({
                    dianpus:[]
                })
                setDianpuchecked(false);
            }
        }
        const dianpuboxonChange=(e)=>{
            setIndeterminate(e.length>0&&e.length!==dianpulist.length)
            setDianpuchecked(e.length>0 && e.length === dianpulist.length);
        }
        const onImageUpload=(file, report)=>{
            return new Promise((resolve, reject) => {
                report(40)
                Service.Upload({
                    action:"uploadimage",
                    file:file,
                    path:'flash',
                    success(result){
                        if(result.retcode==200)
                        {
                            report(100);
                            resolve(result.path);
                        }
                        else
                        {
                            reject();
                        }
                    },
                    fail()
                    {
                        reject();
                    }
                })
            });
        }
        const onImageClick=(e)=>{
            let images=[];
            images.push(e.target.src);
            previewImage({
                images: images,
                index: 0,
                parentComponent: this
            });
        }
        const onImageDeleteClick=()=>{
            setShowimg(false);
        }
        React.useEffect(() => {
            const filedianpus=form.model.get('dianpus');
            const $=filedianpus.value$.subscribe(value => {
                if(value&&value.length) {
                    setIndeterminate(value.length>0&&value.length!==dianpulist.length)
                    setDianpuchecked(value.length>0 && value.length === dianpulist.length);
                }
            });
            return () =>{
                $.unsubscribe();
            };
        }, [form]);

        return(
            <Form
                disabled={loading}
                form={form}
                layout="horizontal"
                onSubmit={onSubmit}>
                <FormInputField
                    name="name"
                    label="名称："
                    required
                    props={{
                        maxLength:15,
                        placeholder:"请输入名称",
                    }}
                    initialValue={editData.name}
                />
                {!showimg&&(
                    <FormImageUploadField
                        label={"图片："}
                        name={"img"}
                        required={!showimg}
                        props={{
                            tips: '单个文件不超过 5M,建议尺寸900x700',
                            maxAmount: 1,
                            maxSize: 1024 * 1024 * 5,
                            onUpload:onImageUpload,
                        }}
                    />
                )}

                {showimg&&(
                    <div className="page-right-body-form">
                        <div className="page-right-body-form-hd">
                            图片：
                        </div>
                        <div className="page-right-body-form-bd">
                            <div className={"page-right-body-form-upimg"}>
                                <img src={editData.img} onClick={onImageClick}/>
                                <div className={"delete"} onClick={onImageDeleteClick}>
                                    <Icon type="reset" />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <FormSelectField
                    name="page"
                    label={"所在位置："}
                    required
                    props={{
                        options:pagelist,
                        placeholder:"请选择所在位置",
                    }}
                    initialValue={Utils.returnItem(editData.page,pagelist)}
                />
                <FormInputField
                    name="url"
                    label="链接店址："
                    required
                    props={{
                        maxLength:1000,
                        placeholder:"请输入链接地址",
                        width:800
                    }}
                    initialValue={editData.url}
                />
                {/*<FormSelectField*/}
                {/*    name="dianpu"*/}
                {/*    label={"所属店铺："}*/}
                {/*    required*/}
                {/*    props={{*/}
                {/*        options:dianpulist,*/}
                {/*        placeholder:"请选择所属店铺",*/}
                {/*    }}*/}
                {/*    initialValue={Utils.returnItem(editData.dianpu,dianpulist)}*/}
                {/*/>*/}
                <div className="page-right-body-form">
                    <div className="page-right-body-form-hd">
                        展示店铺：
                    </div>
                    <div className="page-right-body-form-bd page-border-bottom">
                        <Checkbox
                            checked={dianpuchecked}
                            indeterminate={indeterminate}
                            onChange={dianpuonChange}
                        >全部</Checkbox>
                    </div>
                </div>
                <FormCheckboxGroupField
                    name="dianpus"
                    required="请选择店铺"
                    initialValue={editData.dianpus}
                    validators={[Validators.minLength(1, '至少选择一个店铺')]}
                    onChange={dianpuboxonChange}
                >
                    {dianpulist.map((item,index)=>(
                        <Checkbox key={index} value={item.key}>{item.text}</Checkbox>
                    ))}
                </FormCheckboxGroupField>
                <FormNumberInputField
                    name="sort"
                    label="排序："
                    required
                    props={{
                        max:99999,
                        min:0,
                        placeholder:"请输入排序数字"
                    }}
                    helpDesc={"数字越大排序越靠前"}
                    initialValue={editData.sort||0}
                />
                {/*<FormSwitchField*/}
                {/*    name="role"*/}
                {/*    label="限制："*/}
                {/*    initialValue={editData.role==2?false:true}*/}
                {/*    helpDesc={"是否只允许绑定了店铺的用户查看，不选则所有用户都可以查看"}*/}
                {/*/>*/}
                <FormSwitchField
                    name="state"
                    label="启用："
                    initialValue={editData.state==2?false:true}
                />
                {!stateLoading&&(
                    <div className="form-submit app-fixed-container">
                        <Button type="default" onClick={()=>{_this.props.history.goBack();}}>取消</Button>
                        <Button htmlType="submit" type="primary" loading={loading}>保存</Button>
                    </div>)
                }
            </Form>
        )
    }
    render() {
        const FormView=this.FormView.bind();
        const {loading}=this.state;
        return(
            <div>
                <Header data={[{name:'广告'},{name:'广告管理',href:'/main/ad/ad'},{name:'编辑广告'}]}/>
                <BlockLoading loading={loading}>
                    <div className="page-right-body">
                        <div className="page-right-body-hd"></div>
                        <div className="page-right-body-bd">
                            <FormView/>
                        </div>
                    </div>
                </BlockLoading>
            </div>
        )
    }
}
