import React from 'react';
import Header from '../../../../component/header'
import {
    Button,
    Grid,
    Notify,
    Sweetalert,
    Tag,
    previewImage,
    ClampLines
} from 'zent'
import Service from "../../../../config/Service";

export default class MainAdAdIndex extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            gridPageCurrent:1,
            gridPageTotal:0,
            gridLoading:false,
            gridPageSize:20,
            dataList:[]
        }
    }
    componentWillMount() {
        this.props.parent.onMenuChange(190100);
        this.dataBind(1);
    }
    dataBind=(pageindex)=>{
        const _this=this;
        const {gridPageSize}=this.state;
        this.setState({
            gridLoading:true
        })
        Service.Post({
            name:"webflash",
            action:"pagelist",
            data:{
                type:2,
                pageindex:pageindex,
                pagesize: gridPageSize
            },
            success(result){
                if(result.retcode==200)
                {
                    _this.setState({
                        dataList:result.list,
                        gridPageTotal:result.count
                    })
                }
            },
            fail(){
                Notify.error("网络连接失败");
            },
            complete(){
                _this.setState({
                    gridLoading:false
                })
            }
        })

    }
    onImageClick=(e)=>{
         let images=[];
         images.push(e.target.src);
         previewImage({
          images: images,
          index: 0,
          parentComponent: this
        });
    }
    onAddFlashClick=()=>{
        this.props.history.push('ad/edit/0');
    }
    onGridItemEditClick=(id)=>{
        this.props.history.push('ad/edit/'+id);
    }
    onGridItemDeleteClick=(id)=>{
        const _this=this;
        Sweetalert.confirm({
            content:'操作成功后将无法恢复，请确认',
            title: '请确认操作',
            onConfirm: () => new Promise((resolve,reject) => {
                Service.Post({
                    name:"webflash",
                    action:"delete",
                    data:{
                        id:id
                    },
                    success(result)
                    {
                        if(result.retcode==200)
                        {
                            Notify.success("已删除");
                            resolve();
                            _this.dataBind(_this.state.gridPageCurrent)
                        }
                        else
                        {
                            Notify.warn(result.retmsg);
                            reject();
                        }
                    },
                    fail(){
                        Notify.error("网络链接失败");
                        reject();
                    }
                })
            }),
            parentComponent:this
        });
    }
    onGridItemStatusClick=(id,state)=>{
        this.setState({
            gridLoading:true
        })
        const _this=this;
        Service.Post({
            name:"webflash",
            action:"state",
            data:{
                id,
                state
            },
            success(result)
            {
                _this.setState({
                    gridLoading:false
                })
                if(result.retcode==200)
                {
                    Notify.success("操作成功");
                    _this.dataBind(_this.state.gridPageCurrent);
                }
                else
                {
                    Notify.warn(result.retmsg);
                }
            },
            fail(){
                _this.setState({
                    gridLoading:false
                })
            }
        })
    }
    onGridChange=(e)=>{
        const current=e.current;
        this.setState({
            gridPageCurrent:current
        })
        this.dataBind(current)
    }
    getGridColumns=()=>{
        const listColumns=[
            {
                title:'名称',
                name:'name',
                width: '150px'
            },
            {
                title:'图片',
                width: '150px',
                bodyRender:(data)=>{
                    return<img className="page-right-body-bd-img" src={data.img} onClick={this.onImageClick}/>
                }
            },
            {
                title:'展示店铺',
                name:'dianpus',
            },
            {
                title:'排序',
                name:'sort',
                width: '100px'
            },
            {
                title:'跳转地址',
                name:'url',
                width: '200px',
                bodyRender:data => {
                    return(
                        <ClampLines
                            lines={1}
                            showPop={true}
                            popWidth={"500px"}
                            text={data.url}
                            extra={(
                                <a>更多</a>
                            )}
                        />
                    )
                }
            },
            {
                title:'所在位置',
                width: '150px',
                name:'page',
                textAlign: 'center'
            },
            {
                title:'状态',
                width: '100px',
                bodyRender:(data)=>{
                    let statusView=null;
                    if(data.state==1)
                    {
                        statusView=<Tag theme={"green"}>正常</Tag>
                    }
                    else
                    {
                        statusView=<Tag theme={"yellow"}>停用</Tag>
                    }
                    return <div className="font12">
                        {statusView}
                    </div>
                }
            },
            {
                title:'操作',
                width:'250px',
                textAlign:'center',
                bodyRender:(data,res)=>{
                    let statusView=null;
                    if(data.state==1)
                    {
                        statusView=<Button type="warning" onClick={this.onGridItemStatusClick.bind(this,data.id,2)}>停用</Button>
                    }
                    else
                    {
                        statusView=<Button type="success" onClick={this.onGridItemStatusClick.bind(this,data.id,1)}>启用</Button>
                    }
                    return <div className="font12">
                        <Button type="default" onClick={this.onGridItemEditClick.bind(this,data.id)}>编辑</Button>
                        {statusView}
                        <Button type="error" onClick={this.onGridItemDeleteClick.bind(this,data.id)}>删除</Button>
                    </div>
                }
            }
        ];
        return listColumns;
    }
    render() {
        return(
            <div>
                <Header data={[{name:'广告'},{name:'广告管理'}]}/>
                <div className="page-right-body">
                    <div className="page-right-body-hd">
                        <div className="page-right-body-hd-hd">
                        </div>
                        <div className="page-right-body-hd-bd">
                            <Button onClick={this.onAddFlashClick} type="primary">添加广告</Button>
                        </div>
                    </div>
                    <div className="page-right-body-bd">
                        <Grid
                            columns={this.getGridColumns()}
                            datasets={this.state.dataList}
                            pageInfo={{
                                current: this.state.gridPageCurrent,
                                pageSize: this.state.gridPageSize,
                                total: this.state.gridPageTotal,
                                pageSizeOptions: [this.state.gridPageSize],
                            }}
                            loading={this.state.gridLoading}
                            onChange={this.onGridChange.bind(this)}
                            ellipsis
                            size={"medium"}
                            rowKey="id"/>
                    </div>
                </div>
            </div>
        )
    }
}